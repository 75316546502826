<template>
  <div id="consolidated-certificate" v-if="certificateDetail">
    <Certificate />
    <template v-if="moduleLevelCredential.length > 0">
      <Credential
        v-for="(credential, index) in moduleLevelCredential"
        :courseCredential="credential"
        :key="index"
      ></Credential>
    </template>
  </div>
</template>
<script>
import Certificate from "./Certificate.vue";
import Credential from "../Certificate/CredentialCard.vue";

import { mapGetters } from "vuex";
export default {
  components: {
    Certificate,
    Credential
  },
  computed: {
    ...mapGetters(["certificateDetail"]),
    moduleLevelCredential() {
      return this.certificateDetail?.module_level_credentials;
    }
  },
  mounted() {
    setTimeout(() => {
      this.$i18n.locale = this.language;
      this.$store.commit("SET_LANGUAGE", this.language);
    }, 50);
  }
};
</script>
